import './ButtonSecondary.css'

interface Props {
    label: string,
    onClick: () => void
}

export const ButtonSecondary = (props: Props) => {

    return (
        <div className='button--secondary' onClick={props.onClick}>
            <div className='button--secondary__background'></div>
            <p className="button--secondary__label">{props.label}</p>
        </div>
    )
}