import './EmailForm.css';
import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import Button from "../Button/Button";
import {toast} from "react-toastify";

interface EmailForm {
    email: string,
    name: string,
    message: string
}

const FormComponent = () => {

    const eEmail = {email: '', name: '', message: ''};
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [email, setEmail] = useState(eEmail);
    const [errors, setErrors] = useState(eEmail);
    const [honeyPot, setHoneyPot] = useState('');

    const validate = (field: keyof EmailForm, value: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (field) {
            case 'email':
                if (value && regex.test(value)) {
                    setEmail({...email, email: value});
                    setErrors({...errors, email: ''});
                } else {
                    setErrors({...errors, email: 'Please enter a valid email address.'});
                }
                break;
            case 'name':
                if (value) {
                    setEmail({...email, name: value});
                    setErrors({...errors, name: ''});
                } else {
                    setErrors({...errors, name: 'Your name cannot be empty.'});
                }
                break;
            case 'message':
                if (value) {
                    setEmail({...email, message: value});
                    setErrors({...errors, message: ''});
                } else {
                    setErrors({...errors, message: 'Your message cannot be empty.'});
                }
                break;
        }
    }

    const sendEmail = () => {
        let hasErrors = false;
        (Object.keys(email) as (keyof EmailForm)[]).forEach(key => {
            validate(key, email[key]);
            if (errors[key]) hasErrors = true;
        });

        if (!hasErrors && email !== eEmail && honeyPot === '') {
            const toastId = toast.loading("Sending email...");
            emailjs.send(
                process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                process.env.REACT_APP_EMAIL_TEMPLATE_ID || '',
                email,
                process.env.REACT_APP_EMAIL_USER_ID || '')
                .then((result) => {
                    console.log(result.text);
                    setEmailSent(true);
                    setEmail(eEmail);
                    toast.update(toastId, {
                        render: "Email sent!",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        closeButton: true
                    });
                    (document.getElementById('form1') as HTMLFormElement).reset();
                }).catch((error) => {
                    toast.update(toastId, {
                        render: "Something went wrong :(",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        closeButton: true
                    });
                    console.log(error.text);
                });
        }
    };

    return (
        <>
            <div className="form-container">
                <form id="form1">
                    <div className="input-group">
                        <div className='form-input'>
                            <input type="text" id="name" name="name" placeholder=" " onBlur={
                                (e) => validate('name', e.currentTarget.value)}/>
                            <label className='form-input-label' htmlFor="name">Name</label>
                            <p className={errors.name ? "input-error" : "invisible-validation"}>{errors.name || "P"}</p>
                        </div>

                        <div className='form-input'>
                            <input type="email" id="email" name="email" placeholder=" " onBlur={
                                (e) => validate('email', e.currentTarget.value)}/>
                            <label className='form-input-label' htmlFor="email">Email</label>
                            <p className={errors.email ? "input-error" : "invisible-validation"}>{errors.email || "P"}</p>
                        </div>

                    </div>
                    <div className='form-input'>
                        <textarea className='no-resize' id="message" name="message" placeholder=" " onBlur={
                            (e) => validate('message', e.currentTarget.value)}></textarea>
                        <label className='form-input-label' htmlFor='message'>Message</label>
                        <p className={errors.message ? "input-error" : "invisible-validation"}>{errors.message || "P"}</p>
                    </div>
                    <input type='text' hidden={true} onChange={() => {
                        setHoneyPot("BOT");
                        console.log("BOT!");
                    }}/>

                    <div className='send-button_container'>
                        <Button action={sendEmail} label='Send'/>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FormComponent;
