import './IntroSection.css'
import {ButtonSecondary} from "../ButtonSecondary/ButtonSecondary";
export const IntroSection = () => {
    return (
        <div id='intro' className="container intro-section">
            <p>I live with a lot of <span className="highlight-text">passion</span> for great variety of interests.
                My refined <span className="highlight-text">communication</span> skills make a team environment my natural habitat.
                Working with both <span className="highlight-text">people</span> and animals granted me
                with a strong sense of empathy.
                I step up as a <span className="highlight-text">natural leader</span> when the necessity
                arises.
                My <span className="highlight-text">detail-oriented</span> mindset is the foundation of my
                concept of quality.</p>
            <ButtonSecondary label="More" onClick={()=> console.log('click')}/>
        </div>
    )
}