import { useEffect, useState } from 'react';
import useScroll from '../../hooks/useScroll';
import './scrollBar.css'
import Odometer from './Odometer/Odometer';

// In order for this component to work as it should you need to
// put the class "main-section" to all the element you want this component to indicate on the scroll bar


const ScrollBar = () => {

  const [elements, setElements] = useState<string[]>([]);
  const [elevatorOffset, setElevatorOffset] = useState(0);
  const [position, setPosition] = useState(1);
  const { elementsInView } = useScroll()

  useEffect(() => {
    // Count elements with the class name
    const elements = document.querySelectorAll(`.main-section`);
    console.log("number of main-section-s", elements.length)
    setElements(Array.from(elements).map(el => el.id));
  }, []);


  useEffect(() => {
    const elInView = Array.from(elementsInView)[elementsInView.size - 1];
    if(elements.includes(elInView)) {
      const ZBPosition = elements.indexOf(elInView) 
      setPosition(ZBPosition + 1)
      const offset = ZBPosition * (1 / elements.length) * 100;
      console.log("offset", offset)
      setElevatorOffset(offset);

    }
  }, [elementsInView])

  return (
    <div className="scroll-bar">
      <div className='scroll-bar__number'>
        <Odometer value={position} />
      </div>
      <div className='scroll-bar__track'>
        <div style={{ height: `${(1 / elements.length) * 100}%`, top: elevatorOffset + '%' }} className='scroll-bar__elevator'></div>
      </div>
    </div>
  )
}

export default ScrollBar