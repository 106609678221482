import React from 'react';
import './TechStack.css'; 
import Icon from '../Icon/Icon'; 

// TechStack component
const TechStack: React.FC = () => {
  // List of tech stack icons
  const techStackIcons: string[] = [
    'Git',
    'Github',
    'Nextjs',
    'Axios',
    'Firebase',
    'Typescript',
    'Node',
    'Expo',
    'Express',
    'Json',
    'React',
    'Restapi',
  ];

  return (
    <div className="tech-stack">
      <h2 className="title">Tech Stack</h2>
      <div className="icon-container">
        {techStackIcons.map((iconName, index) => (
          <Icon key={index} name={iconName} />
        ))}
      </div>
    </div>
  );
};

export default TechStack;
