import { ButtonSecondary } from "../ButtonSecondary/ButtonSecondary";
import Skewed from '../Skewed/Skewed';
import './ItemCard.css'

interface Props {
    heading: string,
    image: string,
    id: string
}
export const ItemCard = (props: Props) => {
    const handleClick = () => {
        window.location.href =`/project/${props.id}`
    }

    return (
        <div className="item-card__cont">
                <div className="item-card__header">
                    <h3 onClick={handleClick}>{props.heading}</h3>
                    <ButtonSecondary label="See More" onClick={handleClick}/>
                </div>
            <Skewed>
                <img className="item-card__image" onClick={handleClick} src={require(`../../assets/${props.image}`)} />
            </Skewed>
        </div>
    )
}