import React from 'react'
import Hero from '../../components/Hero/Hero'
import info from '../../assets/info.json'
import TechStack from '../../components/TechStack/TechStack'
import { AboutIntroduction } from '../../components/AboutIntroduction/AboutIntroduction'
import { Skills } from '../../components/Skills/Skills'

const About = () => {
  return (
    <>
    <Hero
  firstLine="My name is"
  secondLine={info.fullName}
  thirdLine="& I'm THE Software Developer"
/>

<AboutIntroduction/>

<TechStack />

<Skills/>
</>

  )
}

export default About