import { ButtonSecondary } from '../ButtonSecondary/ButtonSecondary'
import Button from '../Button/Button'
import EmailForm from '../EmailForm/EmailForm'
import info from '../../assets/info.json'
import './Contact.css'


const Contact = () => {

    return (
        <div id='contact' className='main-section'>
            <div className='text-block'>
                <h5>Don't hesitate to</h5>

                <h3 className='contact-me'>Contact Me</h3>

                </div>
                <div className='text-block'>
                <div className='phone-container'>
                    <ButtonSecondary label={info.phoneNumber} onClick={()=> window.location.href = "tel:+4500000000"}/>
                </div>

                <div className='margin-bottom'>
                    <h5>OR</h5>
                </div>
                <EmailForm />
            </div>
        </div>
    )
}

export default Contact;