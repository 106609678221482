import React from 'react';
import skillsData from './skills.json';
import './Skills.css';


export const Skills = () => {
    return (
        <div id='skills' className="skills-container">
            <h4 className='skills-title'>Skills</h4>
            <div className="skills-list">
                {skillsData.map((skill, index) => (
                    <div key={index} className="skills-item">
                        <div className="skills-info">
                            <span className="skills-name">{skill.name}</span>
                        </div>
                        <div className="empty-circle"></div>
                    </div>
                ))}
            </div>
        </div>
    );
};
