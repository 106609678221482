import React from 'react';
import './globalStyles.css';
import Home from './pages/Home/Home';
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Header from './components/Header/Header';
import {SidebarLogos} from "./components/SidebarLogos/SidebarLogos";
import About from './pages/About/About';
import Project from "./pages/Project/Project";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {

    return (
        <>
            <Router>
                <Header/>
                <SidebarLogos gitLink='https://github.com' linkedinLink='https://linkedin.com'/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/project/:id" element={<Project/>}/>
                </Routes>
            </Router>
            <ToastContainer/>
        </>
    );
}

export default App;
