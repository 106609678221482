import ScrollBar from "../../components/ScrollBar/ScrollBar";
import ScrollButton from "../../components/ScrollButton/ScrollButton";
import './Project.css'
import { useLocation } from "react-router";
import { allProjectsData, ProjectData } from "../../utils/projectData";
import { ReactNode } from "react";

const Project = () => {

    const location = useLocation();
    const projectId = location.pathname.split('/').pop() || '1';
    const projectData = allProjectsData[projectId] as ProjectData;

    return (
        <>
            <ScrollBar />
            <ScrollButton />

            <div className='page-cont'>
                <div id="header" className="project-header main-section">
                    <h3>{projectData.name}</h3>
                    <h6>{projectData.date}</h6>
                    <div className="project-header--image-cont">
                        <img src={require(`../../assets/data/${projectId}/${projectData.img[0]}`)} />
                    </div>
                </div>
                <p className="project-description">{projectData.description}</p>
                <div id="details" className="project-details main-section">
                    <div>
                        <p className="project-details--header">Role:</p>
                        <p>{projectData.role}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Client:</p>
                        <p>{projectData.customer}</p>
                    </div>
                </div>
                <div id="image-cont" className="project--image-cont main-section">
                    {projectData.img.map((img) => {
                        return (<img src={require(`../../assets/data/${projectId}/${img}`)} />)
                    }
                    )}
                </div>
            </div>
        </>
    )
}

export default Project