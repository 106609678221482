import useScroll from '../../hooks/useScroll';
import {ItemCard} from '../ItemCard/ItemCard'
import './journey.css'
import {allProjectsData} from "../../utils/projectData";

/* interface Props {
    ref: React.MutableRefObject<null>
} */

const Journey = (/* props: Props */) => {

    let backgroundImage1 = require("../../assets/top-line.png");
    let backgroundImage2 = {
        backgroundImage: `url(${require("../../assets/line.png")}`
    }

    if (window.matchMedia('(max-width: 992px)').matches) {
        backgroundImage1 = require("../../assets/top-line-m.png");
        backgroundImage2.backgroundImage = `url(${require("../../assets/line-m.png")}`;
    }


    return (
        <div id='journey' className='journey main-section'>
            <div className='journey__header'>
                <h3>My Journey</h3>
                <img src={backgroundImage1}/>
            </div>
            <div className='journey__content' style={backgroundImage2}>
                {Object.keys(allProjectsData).map(key => {
                    return <ItemCard heading={allProjectsData[key].short} image={"gg.png"} id={key} key={key}/>
                })}
            </div>
        </div>
    )
}

export default Journey