import React from 'react';
import Button from '../Button/Button';
import "./Hero.css";
import Skewed from '../Skewed/Skewed';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

interface HeroProps {
  firstLine: string;
  secondLine: string;
  thirdLine: string;
}

const Hero: React.FC<HeroProps> = ({ firstLine, secondLine, thirdLine}) => {

    return (
        <div id='hero' className='hero main-section'>
            <div className='hero__left'>
                <h3 className='text-light'>{firstLine}</h3>
                <h1>{secondLine}</h1>
                <h5 className='hero__subheading'>{thirdLine}</h5>
                <div className='button-container'>  
                    <Button hashLinkTo='/#contact' label='Hire me!' />
                </div>
            </div>
            <div className='hero__right'>
                <Skewed>
                    <img src={require("../../assets/guy.png")} alt="Guy" />
                </Skewed>
            </div>
        </div>
    )
}

export default Hero;
